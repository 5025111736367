$headerHeight: 36px;
$footerHeight: 36px;
$siderLeftWidth: 250px;

.app {
  width: 100%;
  height: 100vh;
  .monitor {
    width: 100%;
    height: 100%;
    overflow: auto;

    .header {
      height: $headerHeight;
    }
    .container {
      display: flex;
      height: calc(100% - $footerHeight - $headerHeight);

      .main {
        width: 100%;
        height: 100%;
      }
    }
    .footer {
      height: $footerHeight;
    }
  }
}
